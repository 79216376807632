function DataAjaxRequest(method, url, async, headers, data) {
    this.$method = method;
    this.$url = url;
    this.$async = async;
    this.$headers = headers;
    this.$data = data;

    // gets and sets methods
    this.getMethod = function() {
        return this.$method;
    };
    this.setMethod = function(method) {
        this.$method = method;
    };

    this.getUrl = function() {
        return this.$url;
    };
    this.setUrl = function(url) {
        this.$url = url;
    };

    this.isAsync = function() {
        return this.$async;
    };
    this.setAsync = function(async) {
        this.$async = async;
    };

    this.getDataAjaxHeaders = function() {
        return this.$headers;
    };
    this.setDataAjaxHeaders = function(headers) {
        this.$headers = headers;
    };

    this.getDataAjaxRequest = function() {
        //return "{" + this.$data + "}";
        return this.$data;
    };
    this.setDataAjaxRequest = function(data) {
        this.$data = data;
    };
    this.pushData = function(data) {
        this.$data = this.$data + ", " + data;
    };

};
