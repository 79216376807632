function Note(createNoteFormIdentifier, token, contentId, contentType, noteIdentifier) {
    this.$createNoteFormIdentifier = createNoteFormIdentifier;
    this.$token = token;
    this.$contentId = contentId;
    this.$contentType = contentType;
    this.$noteIdentifier = noteIdentifier;

    // gets and sets methods
    this.getCreateNoteFormIdentifier = function() {
        return this.$createNoteFormIdentifier;
    };
    this.setCreateNoteFormIdentifier = function(createNoteFormIdentifier) {
        this.$createNoteFormIdentifier = createNoteFormIdentifier;
    };

    this.getToken = function() {
        return this.$token;
    };
    this.setToken = function(token) {
        this.$token = token;
    };

    this.getContentId = function() {
        return this.$contentId;
    };
    this.setContentId = function(contentId) {
        this.$contentId = contentId;
    };

    this.getContentType = function() {
        return this.$contentType;
    };
    this.setContentType = function(contentType) {
        this.$contentType = contentType;
    };

    this.getNoteIdentifier = function() {
        return this.$noteIdentifier;
    };
    this.setNoteIdentifier = function(noteIdentifier) {
        this.$noteIdentifier = noteIdentifier;
    };

};
