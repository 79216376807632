function ProgressHandler(progress) {
    this.$progress = progress;
    this.$type_progress = ['progress-bar-info', 'progress-bar-success', 'progress-bar-warning', 'progress-bar-danger'];

    // methods
    this.getProgress = function() {
        return this.$progress;
    };
    this.reset = function() {
        var progress = this.$progress;
        progress.$content_text = '0% Complete';
        progress.$type_progressbar = 'progress-bar-info';
        $.map(this.$type_progress, function(element) { $(progress.getProgressbarIdentifier()).removeClass(element); });
    };
    this.start = function() {
        $(this.$progress.getProgressIdentifier()).show();
    };
    this.hide = function() {
        $(this.$progress.getProgressIdentifier()).hide();
    };
    this.complete = function() {
        this.reset();
    };
    this.getTypeProgressByPosition = function(index) {
        return this.$type_progress[index];
    };
    this.setProgress = function(progress) {
        $(this.$progress.getProgressbarIdentifier()).css('width', progress + '%');
        $(this.$progress.getProgressbarIdentifier()).html(progress + '% Complete');
    };
    this.setTypeProgress = function(type_progressbar) {
        this.reset();
        $(this.$progress.getProgressbarIdentifier()).addClass(type_progressbar);
    };

};