function Invoice(token, invoiceCommentType, invoiceDiscount, sendFinalRequestUrl, invoiceCurrencySymbol) {
    this.$token = token;
    this.$invoiceCommentType = invoiceCommentType;
    this.$invoiceDiscount = invoiceDiscount;
    this.$sendFinalRequestUrl = sendFinalRequestUrl;
    this.$invoiceCurrencySymbol = invoiceCurrencySymbol;

    var self = this;

    // methods
    this.getToken = function() {
        return this.$token;
    };
    this.setToken = function(token) {
        this.$token = token;
    };

    this.getInvoiceCommentType = function() {
        return this.$invoiceCommentType;
    };
    this.setInvoiceCommentType = function(invoiceCommentType) {
        this.$invoiceCommentType = invoiceCommentType;
    };

    this.getInvoiceDiscount = function() {
        return this.$invoiceDiscount;
    };
    this.setInvoiceDiscount = function(invoiceDiscount) {
        this.$invoiceDiscount = invoiceDiscount;
    };

    this.getSendFinalRequestUrl = function() {
        return this.$sendFinalRequestUrl;
    };
    this.setSendFinalRequestUrl = function(sendFinalRequestUrl) {
        this.$sendFinalRequestUrl = sendFinalRequestUrl;
    };

    this.getInvoiceCurrencySymbol = function() {
        return this.$invoiceCurrencySymbol;
    };
    this.setInvoiceCurrencySymbol = function(invoiceCurrencySymbol) {
        this.$invoiceCurrencySymbol = invoiceCurrencySymbol;
    };

};
