function AjaxRequest(dataAjaxRequest) {
    this.$dataAjaxRequest = dataAjaxRequest;
    this.$statusCode = null;
    this.$beforeSend = null;
    this.$doneAjaxRequest = null;
    this.$failAjaxRequest = null;
    this.$complete = null;

    // gets and sets methods
    this.getDataAjaxRequest = function() {
        return this.$dataAjaxRequest;
    };
    this.setDataAjaxRequest = function(dataAjaxRequest) {
        this.$dataAjaxRequest = dataAjaxRequest;
    };

    this.getStatusCode = function() {
        return this.$statusCode;
    };

    this.getBeforeAjaxRequest = function() {
        return this.$beforeSend;
    };
    this.setBeforeAjaxRequest = function(beforeSend) {
        this.$beforeSend = beforeSend;
    };

    this.getDoneAjaxRequest = function() {
        return this.$doneAjaxRequest;
    };
    this.setDoneAjaxRequest = function(doneAjaxRequest) {
        this.$doneAjaxRequest = doneAjaxRequest;
    };

    this.getFailAjaxRequest = function() {
        return this.$failAjaxRequest;
    };
    this.setFailAjaxRequest = function(failAjaxRequest) {
        this.$failAjaxRequest = failAjaxRequest;
    };

    this.getCompleteAjaxRequest = function() {
        return this.$complete;
    };
    this.setCompleteAjaxRequest = function(complete) {
        this.$complete = complete;
    };

    this.execute = function(formData) {
        var self = this,
            finalFormData = formData != null ? formData : self.$dataAjaxRequest.getDataAjaxRequest();

        $.ajax({
            url: self.$dataAjaxRequest.getUrl(),
            type: self.$dataAjaxRequest.getMethod(),
            headers: self.$dataAjaxRequest.getDataAjaxHeaders(),
            data: finalFormData,
            statusCode : {
                200 : function(){ self.$statusCode = 200 },
                201 : function(){ self.$statusCode = 201 },
                400 : function(){ self.$statusCode = 400 },
                404 : function(){ self.$statusCode = 404 }
            },
            beforeSend: self.$beforeSend != null ? self.$beforeSend : function(){},
            success: self.$doneAjaxRequest != null ? self.$doneAjaxRequest : function(){},
            error: self.$failAjaxRequest != null ? self.$failAjaxRequest : function(){},
            complete: self.$complete != null ? self.$complete : function(){}
        });

    };

};
