function EventsManager(token, contentId, contentType, userId) {
    var self = this;

    this.token = token;
    this.contentId = contentId;
    this.contentType = contentType;
    this.userId = userId;


    $(".js-select2").select2({
        width: '100%'
    });

    $(".js-select2-custom").select2({
        width: '100%',
        placeholder: " + Add guests",
    });

    $('.timepicker').datetimepicker({
        format: 'HH:mm'
    });

    $('.datetimepicker-formatted').datetimepicker({
        'format': 'DD MMM YYYY'
    });

    $('[data-toggle="popover"]').popover({
        template: '<div class="popover lg-popover"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div></div>'
    });

    $('.datetimepicker-formatted-start-date').on('dp.change', function () {
        var start = moment($('.datetimepicker-formatted-start-date').find('input').val(), "DD-MMM-YYYY");
        var end = moment($('.datetimepicker-formatted-end-date').find('input').val(), "DD-MMM-YYYY");

        if(end.diff(start, 'days') < 0) {
            $('.datetimepicker-formatted-end-date').datetimepicker({});
            $('.datetimepicker-formatted-end-date').data("DateTimePicker").date( start );
        }
    });

    this.getEntries = function() {
        var self = this;

        $.ajax({
            url: '/events/index',
            headers: {
                'X-CSRF-TOKEN': self.token
            },
            type: 'POST',
            async: true,
            data: { content_id : self.contentId, content_type : self.contentType },
            success: function (result) {
                $("#list").html(result);
                $('[data-toggle="popover"]').popover({
                    template: '<div class="popover lg-popover"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div></div>'
                });
            }
        });

        $.ajax({
            url: '/events/index-history',
            headers: {
                'X-CSRF-TOKEN': self.token
            },
            type: 'POST',
            async: true,
            data: { content_id : self.contentId, content_type : self.contentType },
            success: function (result) {
                $("#list-history").html(result);
                $('[data-toggle="popover"]').popover({
                    template: '<div class="popover lg-popover"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div></div>'
                });
            }
        });
    };

    this.getCreate = function() {
        this.cleanForm();
        $("#showCreate").find(".btn-submit").attr("onclick","eventsManager.createEntry()");

        $("#showCreate").on('shown.bs.modal', function (e) {
            $('#showCreate').find('input[name=title]').trigger("focus");
        });

        $(".js-select2-custom").prop("disabled", false);

        $("#showCreate").modal({show: true});

        if(this.userId) {
            $(".js-select2-custom").val([this.userId]).trigger('change');
            $("select[name=responsible]").val(this.userId).trigger('change');
        }

        $('#showCreate').find('#priority').val(window.Laravel.eventPriorityDefault).trigger('change');

        $('#showCreate').find('input[name=start_date]').val(moment().format('DD MMM YYYY'));
        $('#showCreate').find('input[name=end_date]').val(moment().format('DD MMM YYYY'));

        $('#showCreate').find('input[name=start_time]').val(moment().add(1, 'h').startOf('hour').format('HH:mm'));
        $('#showCreate').find('input[name=end_time]').val(moment().add(2, 'h').startOf('hour').format('HH:mm'));
    };

    this.createEntry = function() {
        var self = this;

        $.ajax({
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': self.token
            },
            url: $("#event-create-form").attr('action'),
            data: $("#event-create-form").serialize(),
            success: function () {

                if(self.contentId == null) {
                    location.reload();
                    return;
                }
                
                self.cleanForm();
                self.getEntries();
                $('#showCreate').modal('hide');
                self.reloadWidgetSection();
            },
            error: function(data) {
                if (data.status == 422) {
                    $('.validation-errors').html(data.responseJSON.view);

                    $('#showCreate').find('input,textarea,select').not('input[type=search],input[type=hidden],input[type=checkbox]').each(function(index, element) {
                        self.cleanValidation($(element).attr('name'));
                    });

                    Object.keys(data.responseJSON.errors).map(function(objectKey) {
                        self.checkValidation(objectKey);
                    });
                }
                else {
                    $('.validation-errors').html('<div class="row"><div class="col-lg-12"><div class="alert alert-danger">Something went wrong, please try again later or contact administrator.</div></div></div>');
                }
            }
        });
    };

    this.entryDelete = function(id) {
        if(!confirm('Do you really want to delete this entry?')) return false;

        var self = this;
        var $container = $(".container-entry-"+id);

        $.ajax({
            url: '/events/delete',
            type: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': self.token
            },
            data: "id="+id,
            beforeSend : function() {
                $container.addClass('is-disabled');
            },
            success: function () {
                $container.slideUp('fast', function(){$container.remove()});
                self.reloadWidgetSection();
            },
            error: function() {
                $container.removeClass('is-disabled');
            }
        });
    };

    this.entryEdit = function(id) {
        this.cleanForm();
        var $container = $(".container-entry-"+id);

        $.ajax({
            url: '/events/update/'+id,
            type: 'get',
            beforeSend : function() {
                $container.addClass('is-disabled');
                $("#showCreate").find(".btn-submit").attr("onclick","eventsManager.entryEditSubmit("+id+")");
            },
            success: function (data) {

                if(data.object && data.object_href && data.object_name && data.object_type) {
                    $('#showCreate').find('.attach_event_main_container').show();
                    $('#showCreate').find('input[name=content_type]').val(data.object_type);
                    $('#showCreate').find('input[name=content_id]').val(data.object.id);
                    $('#showCreate').find('.attach_event_main_container').html("" +
                        '<div class="form-group col-md-12 attach_event_container">'+
                            '<input id="attach_event" name="attach_event" checked="checked" type="checkbox" value="1">&nbsp;' +
                            '<label class="control-label inline" for="attach_event"><strong>Attach this event to object? <a target="_blank" href="'+data.object_href+'">'+data.object_name+'</a></strong></label>' +
                        '</div>'
                    );
                }
                else {
                    $('#showCreate').find('input[name=content_type]').val("");
                    $('#showCreate').find('input[name=content_id]').val("");
                    $('#showCreate').find('.attach_event_main_container').hide();
                }

                $('#showCreate').find('#priority').val(data.event.priority).trigger('change');

                $('#showCreate').find('input[name=title]').val(data.event.title);
                $('#showCreate').find('textarea[name=description]').val(data.event.description);
                $('#showCreate').find('input[name=is_actionable]').prop("checked", data.event.is_actionable);
                $('#showCreate').find('input[name=is_disabled]').prop("checked", data.event.is_disabled);

                $('#showCreate').find('input[name=all_day]').prop("checked", data.event.all_day);
                $('#showCreate').find('input[name=all_day]').trigger('change');

                $('#showCreate').find('input[name=is_once]').prop("checked", data.rule.is_once);
                $('#showCreate').find('input[name=is_once]').trigger('change');

                $('#showCreate').find('select[name=type]').val(data.rule.type);
                $('#showCreate').find('select[name=type]').trigger('change');

                $('#showCreate').find('#day_of_period').val(data.rule.day_of_period);
                $('#showCreate').find('#day_of_period').trigger('change');

                $('#showCreate').find('#every').val(data.rule.interval);
                $('#showCreate').find('#every').trigger('change');

                $('#showCreate').find('input[name=start_date]').val(data.rule.start_date);
                $('#showCreate').find('input[name=end_date]').val(data.rule.end_date);
                $('#showCreate').find('input[name=start_time]').val(data.rule.start_time);
                $('#showCreate').find('input[name=end_time]').val(data.rule.end_time);

                if(data.responsible) {
                    $('#showCreate').find(".js-select2").val(data.responsible);
                    $('#showCreate').find(".js-select2").trigger('change');
                }

                $(".js-select2-custom").val(data.users);
                $(".js-select2-custom").trigger('change');
                $(".js-select2-custom").prop("disabled", false);

                $('#showCreate').modal({show:true});
                $container.removeClass('is-disabled');
            },
            error: function() {
                alert('Something wrong with entry, please contact administrator.');
                $container.removeClass('is-disabled');
            }
        });
    };

    this.entryEditSubmit = function(id) {
        var self = this;

        $.ajax({
            url: '/events/patch',
            type: 'PATCH',
            headers: {
                'X-CSRF-TOKEN': self.token
            },
            data: $("#event-create-form").serialize() + "&id=" + id,
            success: function () {

                if(self.contentId == null) {
                    location.reload(true);
                    return;
                }

                self.cleanForm();
                self.getEntries();
                $('#showCreate').modal('hide');
                self.reloadWidgetSection();
            },
            error: function(data) {
                if (data.status == 422) {
                    $('.validation-errors').html(data.responseJSON.view);

                    $('#showCreate').find('input,textarea,select').not('input[type=search],input[type=hidden],input[type=checkbox]').each(function(index, element) {
                        self.cleanValidation($(element).attr('name'));
                    });

                    Object.keys(data.responseJSON.errors).map(function(objectKey) {
                        self.checkValidation(objectKey);
                    });
                }
                else {
                    $('.validation-errors').html('<div class="row"><div class="col-lg-12"><div class="alert alert-danger">Something went wrong, please try again later or contact administrator.</div></div></div>');
                }
            }
        });
    };

    this.entryView = function(id, statusDate) {
        $.ajax({
            url: '/events/update/'+id+'?view=1&status_date='+statusDate,
            type: 'get',
            success: function (data) {

                $('#showDetails').find('#title').text(data.event.title);
                $('#showDetails').find('#priority').text(data.priority);
                $('#showDetails').find('#created').text(data.created);

                if(data.updated.trim() == "") {
                    $('#showDetails').find('#updated').closest('.form-group').hide();
                }
                else {
                    $('#showDetails').find('#updated').text(data.updated);
                    $('#showDetails').find('#updated').closest('.form-group').show();
                }

                if(data.event.description.trim() == "") {
                    $('#showDetails').find('#description').closest('.form-group').hide();
                }
                else {
                    $('#showDetails').find('#description').text(data.event.description);
                    $('#showDetails').find('#description').closest('.form-group').show();
                }

                if(data.object && data.object_href && data.object_name) {
                    $('#showDetails').find('.attach_event_container_view').show();
                    $('#showDetails').find('.attach_event_container_view').html("Attached to object <a target='_blank' href='"+data.object_href+"'>"+data.object_name+"</a><div>&nbsp;</div>");
                }
                else {
                    $('#showDetails').find('.attach_event_container_view').hide();
                }

                $('#showDetails').find('input[name=is_actionable_view]').prop("checked", data.event.is_actionable).trigger('change');
                $('#showDetails').find('input[name=is_disabled_view]').prop("checked", data.event.is_disabled).trigger('change');
                $('#showDetails').find('input[name=all_day_view]').prop("checked", data.event.all_day).trigger('change');
                $('#showDetails').find('input[name=is_once_view]').prop("checked", data.rule.is_once).trigger('change');

                $('#showDetails').find('#rule').text(data.rule.readable);
                $('#showDetails').find('#next-occurrence').html(data.rule.occurrences.join("<br>"));

                if(data.responsible) {
                    $('#showDetails').find('#responsible-view').text(data.responsible.first_name + " " + data.responsible.last_name);
                }

                if(data.users.length > 0) {
                    $(".guests-field-view").show();
                    $(".js-select2-custom").val(data.users);
                    $(".js-select2-custom").trigger('change');
                    $(".js-select2-custom").prop("disabled", true);
                }
                else {
                    $(".guests-field-view").hide();
                }

                $('#showDetails').find('#status').removeClass('label-success label-warning');
                $('#showDetails').find('#status').addClass('label-'+data.status_class);
                $('#showDetails').find('#status').text(data.status);
                if(data.status_done) {
                    $('#showDetails').find('#completedBy').closest('div').show();
                    $('#showDetails').find('#completedBy').text(data.status_user);
                }
                else {
                    $('#showDetails').find('#completedBy').closest('div').hide();
                }

                if(data.status_exists) {
                    $('.status-input').show();
                }
                else {
                    $('.status-input').hide();
                }

                $('#showDetails').modal({show:true});
            },
            error: function() {
                alert('Something wrong with entry, please contact administrator.');
            }
        });
    };

    this.checkValidation = function(name) {
        name = name.replace('[]','');
        $('#showCreate').find(
            'input[name='+name+'], textarea[name='+name+'], select[name='+name+'], select.'+name
        ).not('input[type=search],input[type=hidden],input[type=checkbox]')
        .closest('.form-group').addClass('has-error');
    };

    this.cleanValidation = function(name) {
        name = name.replace('[]','');
        $('#showCreate').find(
            'input[name='+name+'], textarea[name='+name+'], select[name='+name+'], select.'+name
        ).not('input[type=search],input[type=hidden],input[type=checkbox]')
        .closest('.form-group').removeClass('has-error');
    };

    this.cleanForm = function() {

        if(this.contentId == null) {
            $('#showCreate').find('.attach_event_main_container').html('');
        }

        $('#showCreate').find('input,textarea,select').not('input[type=search],input[type=hidden],input[type=checkbox]').each(function(index, element) {
            $(element).val('');
            $(element).closest('.form-group').removeClass('has-error');
        });
        $('.validation-errors').html('');
        $('#showCreate').find('.js-select2-custom').trigger('change');
    };

    this.populateRepeatOn = function(type) {
        var array = [];
        $('.day_of_period_container').show();

        if (type == 'DAILY') {
            for (var i = 0; i <= 23; i++) {
                array.push({value: i, text: (i < 10 ? "0" : "") + i + ":00" });
            }
        }
        else if (type == 'WEEKLY') {
            array = [
                {value: "MO", text: "Monday"},
                {value: "TU", text: "Tuesday"},
                {value: "WE", text: "Wednesday"},
                {value: "TH", text: "Thursday"},
                {value: "FR", text: "Friday"},
                {value: "SA", text: "Saturday"},
                {value: "SU", text: "Sunday"}
            ];
        }
        else if (type == 'MONTHLY') {
            for (var i = 1; i <= 31; i++) {
                array.push({value: i, text: i});
            }
        }
        else {
            $('.day_of_period_container').hide();
            return;
        }

        var select = document.getElementById('day_of_period'),
            option,
            i = 0,
            il = array.length;

        $(select).empty();
        for (; i < il; i++) {
            option = document.createElement('option');
            option.setAttribute('value', array[i].value);
            option.appendChild(document.createTextNode(array[i].text));
            select.appendChild(option);
        }
    };

    this.populateRepeatOn($('select[name=type]').val());
    $('body').on('change', 'select[name=type]', function() {
        self.populateRepeatOn($(this).val());
    });

    this.handleRecurring = function() {
        if ( $('input[name=is_once]').is(':checked') )
            $('.recurring_container').hide();
        else {
            $('.recurring_container').show();
            $('select[name=type]').val($('select[name=type]').val()).trigger('change');
        }
    };

    this.handleRecurring();
    $('body').on('change', 'input[name=is_once]', function() {
        self.handleRecurring();
    });


    this.handleTime = function() {
        if ( $('input[name=all_day]').is(':checked') ) {
            $('#showCreate').find('input[name=start_time]').closest('.form-group').hide();
            $('#showCreate').find('input[name=end_time]').closest('.form-group').hide();
        }
        else {
            $('#showCreate').find('input[name=start_time]').closest('.form-group').show();
            $('#showCreate').find('input[name=end_time]').closest('.form-group').show();
        }
    };

    this.handleTime();
    $('body').on('change', 'input[name=all_day]', function() {
        self.handleTime();
    });

    this.entryStatus = function(id, status) {
        var self = this;
        var $container = $(".container-entry-"+id);

        $.ajax({
            url: '/events/status',
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': self.token
            },
            data: "id="+id+"&status="+status,
            beforeSend : function() {
                $container.addClass('is-disabled');
            },
            success: function () {
                if(self.contentId == null) {
                    location.reload();
                    return;
                }
            },
            error: function() {
                $container.removeClass('is-disabled');
            }
        });
    };

    this.reloadWidgetSection = function() {
        var $container = $('.events-notification');
        $.ajax({
            url: window.eventsWidgetUrl,
            type: 'get',
            beforeSend : function() {
                $container.css('opacity', 0.5);
            },
            success: function (data) {
                $container.html(data.view);
            },
            complete: function() {
                $container.css('opacity', 1);
            }
        });
    }
};
