function FreeAgentEstimateView($currency, $quantityIdElement, $itemTypeIdElement, $priceIdElement, $descriptionIdElement, $routes) {
    this.$currency = $currency;
    this.$quantityIdElement = $quantityIdElement;
    this.$itemTypeIdElement = $itemTypeIdElement;
    this.$priceIdElement = $priceIdElement;
    this.$descriptionIdElement = $descriptionIdElement;
    this.$routes = $routes;
    // $routes
    // delete : delete an estimate item

    var $self = this;
    var $utilClass = new Utils();
    var $total = $('#total');

    // methods
    this.initView = function() {
        // textarea autosize
        autosize($($self.$descriptionIdElement));
        $($self.$descriptionIdElement).each(function() {
            autosize(this);
        }).on('autosize:resized', function() {
            //
        }).on('keyup', function() {
            if((($(this).val().match(/\n/g) || []).length + 1) === 1) {
                $(this).css('height', '34');
            } else {
                $(this).css('min-height', 'auto');
            }
        });

        // sortable table
        $('.sorted-table').sortable({
            containerSelector: 'table',
            itemPath: '> tbody',
            itemSelector: 'tr.table-tr-reorder-identifier',
            placeholder: '<tr class="placeholder"/>',
            onDrop: function ($item, container, _super, event) {
                _super($item, container);
                $item.closest('table').find('tbody tr').each(function (i, row) {
                    var $row = $(row);
                    $row.find('.form-row-position').val(i);
                });

                $self.submitEstimateItems();
            }
        });

        // redraw form
        $('.toggle-type').map(function(){
            $self.checkFormType($(this));
        });

        // add estimate item
        var table = $('.table.view-invoice-content-table')[0];
        $(table).delegate('#estimate-create-new-estimate-item', 'click', function() {
            var thisRow = $('#unique-estimate-item-form').closest('tr');
            var tableRow = $(thisRow).clone();
            tableRow.removeClass('hidden-by-default');
            $('.table.view-invoice-content-table').last().append(tableRow);
            $('.table.view-invoice-content-table').last().find('.form-row-position input[type="hidden"]').val($('.table.view-invoice-content-table tr').length + 1);
        });

        // remove estimate item when isn't saved
        $(document).on('click', '.preview-btn-rm-row-form', function() {
            if (confirm('Are you sure?')) {
                var $tdNode = $(this).closest('.estimate-item-form');
                $tdNode.remove();
                $self.recalculatePrices();
            }
        });

        // add button click
        $('.btn-add-row-form').click(function() {
            $self.submitEstimateItems();
        });

        // rm button click
        $(document).on('click', '.btn-rm-row-form', function() {
            if (confirm('Are you sure?')) {
                var $tdNode = $(this).closest('.table-row-form-added'),
                    $estimateId = $tdNode.data('estimateid'),
                    $estimateItemId = $tdNode.data('estimateitemid');

                if ($.isNumeric($estimateId) && $.isNumeric($estimateItemId)) {
                    var $ajaxRequest = new AjaxRequest(
                        new DataAjaxRequest(
                            'DELETE',
                            $self.$routes.delete.replace("estimate_id", $estimateId).replace("estimate_item_id", $estimateItemId),
                            false,
                            { 'X-CSRF-TOKEN' : window.Laravel.csrfToken },
                            { estimateId : $estimateId, estimateItemId : $estimateItemId }
                        )
                    );
                    $ajaxRequest.setDoneAjaxRequest(function(response, status) {
                        // done
                    });
                    $ajaxRequest.setCompleteAjaxRequest(function() {
                        window.location.reload();
                    });
                    $ajaxRequest.execute();
                } else {
                    // added in UI - not in system yet
                    $tdNode.remove();
                }
            }
        });

        // redraw form
        $(document).on('change', '.toggle-type', function() {
            $self.checkFormType($(this));
        });

        // recalculate price
        $self.recalculatePrices();

        $(document).on('change paste keyup', '.price', function(e) {
            e.preventDefault();
            if($(this).val() !== '-' && !$.isNumeric($(this).val())) {
                $(this).val($(this).val().slice(0, -1));
                alert('Price is not a valid!');

                return false;
            }
            $self.recalculatePrices();
        });

        $(document).on('change paste keyup', '.quantity', function(e) {
            e.preventDefault();
            if(!$.isNumeric($(this).val())) {
                $(this).val($(this).val().slice(0, -1));
                alert('Quantity is not a valid!');

                return false;
            }
            $self.recalculatePrices();
        });

        $('.input-type-only-float-positive-digits').on('keydown', function(e) {
            $utilClass.onlyFloatPositiveDigits(e);
        });

        $('.input-type-only-digits').on('keydown', function(e) {
            $utilClass.onlyDigits(e);
        });
    };

    this.submitEstimateItems = function() {
        var $sendFinalRequest = true;
        var $form = $('form.form-estimate-items');
        var $data = { estimate : $form.find('#estimate').val(), estimate_item : [] };
        $('.table-body').find('tr').each(function(i, row) {
            if(i > 0 && $sendFinalRequest) {
                // i > 0 - because first tr from table is row that will be cloned for add estimate item (it contains empty inputs)

                var $row            = $(row);
                var estimateItemId  = $row.find('#estimate_item_id').val();
                var action          = $row.find('#action').val();
                var position        = $row.find('.form-row-position').val();
                var quantity        = $row.find('.quantity').val();
                var itemType        = $row.find('.toggle-type.item_type').val();
                var description     = $row.find('.description').val();
                var price           = $row.find('.price').val();

                if ((quantity === "" || itemType === "" || description === "" || price === "")) {
                    $sendFinalRequest = false;
                    alert('Please fill all fields.');
                    return;
                }

                $data.estimate_item.push({
                    'action'            : action,
                    'estimate_item_id'  : estimateItemId,
                    'position'          : position,
                    'quantity'          : quantity,
                    'item_type'         : itemType,
                    'description'       : description,
                    'price'             : price
                });
            }
        });

        if ($sendFinalRequest && $data.estimate_item.length > 0) {
            var $ajaxRequest = new AjaxRequest(
                new DataAjaxRequest(
                    'POST',
                    $form.attr('action'),
                    false,
                    { 'X-CSRF-TOKEN' : window.Laravel.csrfToken },
                    $data
                )
            );
            $ajaxRequest.setDoneAjaxRequest(function(response, status) {
                // done
            });
            $ajaxRequest.setCompleteAjaxRequest(function() {
                window.location.reload();
            });
            $ajaxRequest.execute();
        } else {
            $.bootstrapGrowl("Please add Estimate items.", { delay : 5000, type : "warning"});
        }
    };

    this.checkFormType = function($this) {
        $self.toggleForm(
            $this.closest('.table-form-container-row').find('.quantity'),
            $this.closest('.table-form-container-row').find('.price')
        );
    };

    this.toggleForm = function($quantity, $price) {
        $quantity.css('visibility', 'visible');
        $price.css('visibility', 'visible');

        if($quantity.attr('id') !== 'quantity') {
            $quantity.prop('required', true);
        }
        if($price.attr('id') !== 'price') {
            $price.prop('required', true);
        }

        $self.recalculatePrices();
    };

    this.recalculatePrices = function() {
        var netSubtotal = 0;
        $('.table-body').find('tr').each(function(i, row) {
            var $row      = $(row);
            var $net      = $row.find('.net');
            var quantity  = $row.find('.quantity').val();
            var price     = $row.find('.price').val();
            var net = quantity * price;
            netSubtotal += net;

            $net.text($utilClass.formatPriceWithoutCurrency(net));
        });
        $total.html('<strong>' + $self.$currency + " " + $utilClass.formatPriceWithoutCurrency(netSubtotal) + '</strong>');
    };

};
