function FileUploadHandler() {
    this.readonly = "";
    this.listFilesIdentifier = "";
    this.token = "";
    this.content_id = "";
    this.content_type = "";

    this._instance = this;

    this.init = function(){
        $(document).on('change.bs.fileinput', '.fileinput', function(e) {
            var $this = $(this),
                $input = $this.find('input[type=file]'),
                $span = $this.find('.fileinput-filename');
            if($input[0].files !== undefined && $input[0].files.length > 1) {
                $span.text($.map($input[0].files, function(val) { return val.name; }).join(', '));
            }
            $span.attr('title', $span.text());
        });
        this.getFiles();
    };


    this.getFiles = function() {
        this.dimFiles();
        var that = this;
        $.ajax({
            url: "/files/index",
            headers: {
                'X-CSRF-TOKEN': this.token
            },
            type: 'POST',
            data: "readonly=" + this.readonly + "&content_id="+this.content_id+"&content_type="+this.content_type,
            async : true,
            success: function (result) {
                //console.log(that.listFilesIdentifier);

                $(that.listFilesIdentifier).html(result).promise().done(function(){

                    $(that.listFilesIdentifier).find('a.file-delete-link').each(function(index){
                        $(this).on("click", function(e){
                            e.preventDefault();
                            that.delete(that,$(this));
                        });
                    });
                    that.showFiles();
                });
            },
            error: function() {
                that.showFiles();
                $.bootstrapGrowl("There has been an error", {delay:2000, type:"danger"});
            }
        });
    };

    this.delete = function (that,obj) {
        if(!confirm('Do you really want to delete this file?')) return false;
        this.toggleDimFiles();
        var id = $(obj).data('id');
        var url = $(obj).data('url');
        $container = $("tr#file-"+id);

        var that = this;

        $.ajax({
            url: url,
            type: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': this.token
            },
            success: function (result) {
                $container.effect("highlight", {}, 100, function(){$container.slideUp('fast', function(){$container.remove()})});
                that.toggleDimFiles();
                $.bootstrapGrowl("File has been successfully deleted.", {delay:2000,type:"success"});
            },
            error: function() {
                that.toggleDimFiles();
                $.bootstrapGrowl("There has been an error", {delay:2000,type:"danger"});

            }
        });
    };


    this.toggleDimFiles = function() {
        if($(this.listFilesIdentifier).css('opacity')<1) {
            $(this.listFilesIdentifier).css('opacity',1);
            $('.add-files-form').css('opacity',1);
            $("upload-animation").hide();

        } else {
            $("upload-animation").show();
            $(this.listFilesIdentifier).css('opacity',0.5);
            $('.add-files-form').css('opacity',0.5);
        }
    };

    this.dimFiles = function() {
        $("upload-animation").show();
        $(this.listFilesIdentifier).css('opacity',0.5);
        $('.add-files-form').css('opacity',0.5);
    };

    this.showFiles = function() {
        $(this.listFilesIdentifier).css('opacity',1);
        $('.add-files-form').css('opacity',1);
        $("upload-animation").hide();
    };


}
