$(document).ready(function() {
    var $btnLoadFreeAgentContacts     = '#btn_load_free_agent_contacts',
        $checkboxFreeAgentIntegration = '#checkbox_free_agent_integration',
        $selectFreeAgentIntegration   = '#select_free_agent_integration',
        $freeAgentContactName         = '#free_agent_contact_name',
        $divFreeAgentIntegration      = '#div_free_agent_integration',
        $linkedContact                = '#linked_contact';

    $($checkboxFreeAgentIntegration).click(function() {
        var $loadFreeAgentContacts = '#load_free_agent_contacts',
            $loadFreeAgentContactsText = 'Load Contact';

        if($(this).is(':checked')) {
            $('#checkbox_title').html('Enabled');
            $($loadFreeAgentContacts).removeClass('hidden');
            $($btnLoadFreeAgentContacts).removeClass('hidden');
        } else {
            $('#checkbox_title').html('Disabled');

            $($divFreeAgentIntegration).addClass('hidden');
            $($loadFreeAgentContacts).addClass('hidden');
            $loadFreeAgentContactsText = 'Change Contact';
            $($btnLoadFreeAgentContacts).addClass('hidden');

            // remove all contacts
            $($selectFreeAgentIntegration).empty();
            $($freeAgentContactName).val('');
        }
        $($loadFreeAgentContacts).text($loadFreeAgentContactsText);

    });

    $($btnLoadFreeAgentContacts).click(function(e) {
        e.preventDefault();

        $.ajax({
            type : 'GET',
            url : '/free-agent/contacts',
            beforeSend : function() {
                toggleLoadContacts($btnLoadFreeAgentContacts);
            },
            success : function (result) {
                try {
                    $($selectFreeAgentIntegration).removeClass('hidden');
                    $($selectFreeAgentIntegration).empty();
                    $($divFreeAgentIntegration).removeClass('hidden');
                    $($linkedContact).removeClass('hidden');
                    $($linkedContact).html('Linked contact:&nbsp;&nbsp;<b>' + ($($freeAgentContactName).val() == '' ? $.parseJSON(result)[0].name : $($freeAgentContactName).val()) + '</b>');
                    $.map($.parseJSON(result), function (element, index) {
                        $($selectFreeAgentIntegration).append("<option value='" + element.url + "'>" + element.name + "</option>");
                    });
                    $($freeAgentContactName).val($.parseJSON(result)[0].name);
                } catch(err) {
                    $.bootstrapGrowl("Could not retrieve any data.", {delay:2000, type:"danger"});
                }
            },
            error : function() {
                $.bootstrapGrowl("There has been an error", {delay:2000, type:"danger"});
            },
            complete : function() {
                toggleLoadContacts($btnLoadFreeAgentContacts);
            }
        });
    });

    $($selectFreeAgentIntegration).on('change', function() {
        $($freeAgentContactName).val($('#select_free_agent_integration option:selected').text());
        $($linkedContact).removeClass('hidden');
        $($linkedContact).html('Linked contact:&nbsp;&nbsp;<b>' + $('#select_free_agent_integration option:selected').text() + '</b>');
    });
});

function toggleLoadContacts(elementIdentifier) {
    var buttonText = $(elementIdentifier).text();
    if($(elementIdentifier).hasClass('disabled')) {
        $(elementIdentifier).html('');
        $(elementIdentifier).append(buttonText);
        $(elementIdentifier).removeClass('disabled');
    } else {
        $(elementIdentifier).html($('<i class="fa fa-cog fa-spin fa-fw"></i>'));
        $(elementIdentifier).append(buttonText);
        $(elementIdentifier).addClass('disabled');
    }
}