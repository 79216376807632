var Progress = (function () {
    function Progress(progress_identifier, progressbar_identifier, type_progressbar, content_text) {
        this.$progress_identifier = progress_identifier;
        this.$progressbar_identifier = progressbar_identifier;
        this.$content_text = content_text;
        this.$type_progressbar = type_progressbar;
    }

    // methods
    Progress.prototype.getProgressIdentifier = function() {
        return this.$progress_identifier;
    };
    Progress.prototype.setProgressIdentifier = function (progress_identifier) {
        this.$progress_identifier = progress_identifier;
    };

    Progress.prototype.getProgressbarIdentifier = function () {
        return this.$progressbar_identifier;
    };
    Progress.prototype.setProgressbarIdentifier = function (progressbar_identifier) {
        this.$progressbar_identifier = progressbar_identifier;
    };

    Progress.prototype.getContentText = function () {
        return this.$content_text;
    };
    Progress.prototype.setContentText = function (content_text) {
        this.$content_text = content_text;
    };

    Progress.prototype.getTypeProgressbar = function () {
        return this.$type_progressbar;
    };
    Progress.prototype.setTypeProgressbar = function (type_progressbar) {
        this.$type_progressbar = type_progressbar;
    };

    return Progress;
})();