function InvoiceView(invoice) {
    this.$invoice = invoice;

    var self = this;

    // methods
    this.getInvoice = function() {
        return this.$invoice;
    };
    this.setInvoice = function(invoice) {
        this.$invoice = invoice;
    };

    var utilClass = new Utils();
    var commentType, $invoiceDiscount, $discount, $netSubtotal, $vatSubtotal, $total, $invoiceEstimateItemForm, $lastSavedRow;

    this.initInvoiceView = function() {
        // textarea autosize
        autosize($('textarea.details'));
        $('textarea.details').each(function() {
            autosize(this);
        }).on('autosize:resized', function() {
            //
        }).on('keyup', function() {
            var numberOfLines = ($(this).val().match(/\n/g) || []).length + 1;
            if(numberOfLines == 1) {
                $(this).css('height', '34');
            } else {
                $(this).css('min-height', 'auto');
            }
        });

        commentType = self.getInvoice().getInvoiceCommentType();
        $invoiceDiscount = parseFloat(self.getInvoice().getInvoiceDiscount());
        $discount    = $('#discount');
        $netSubtotal = $('#net_subtotal');
        $vatSubtotal = $('#vat_subtotal');
        $total       = $('#total');
        $invoiceEstimateItemForm = $('.invoice-estimate-item-form');
        $lastSavedRow = $('.table.view-invoice-content-table tbody tr').length;

        $(function() {

            // sortable table
            $('.sorted-table').sortable({
                distance: 10,
                containerSelector: 'table',
                itemPath: '> tbody',
                itemSelector: 'tr.table-tr-reorder-identifier',
                placeholder: '<tr class="placeholder"/>',
                onDrop: function ($item, container, _super, event) {
                    _super($item, container);
                    $item.closest('table').find('tbody tr').each(function (i, row) {
                        var $row = $(row);
                        $row.find('.form-row-order').val(i);
                    });
                }
            });


            // redraw form
            $('.toggle-type').map(function(){
                self.checkFormType($(this));
            });

            var index = 0;

            // add estimate item
            var table = $('.table.view-invoice-content-table')[0];
            $(table).delegate('#invoice-create-new-estimate-item', 'click', function() {
                var thisRow = $('#unique-invoice-estimate-item-form').closest('tr');
                var tableRow = $(thisRow).clone();
                tableRow.removeClass('hidden-by-default');
                $('.table.view-invoice-content-table').last().append(tableRow);
                $('.table.view-invoice-content-table').last().find('.form-row-order input[type="hidden"]').val($('.table.view-invoice-content-table tr').length + 1);
            });

            // remove estimate item when isn't saved
            $(document).on('click', '.preview-btn-rm-row-form', function() {
                if(!confirm('Are you sure?')) {
                    return;
                }

                var $tdNode = $(this).closest('.invoice-estimate-item-form');
                $tdNode.remove();
                self.recalculatePrices();
            });

            // add button click
            $('.btn-add-row-form').click(function() {
                var sendFinalRequest = true;
                $('.table-body').find('tr').each(function (i, row) {
                    if(i > 0 && sendFinalRequest) {
                        // i > 0 - because first tr from table is row that will be cloned for add estimate item (it contains empty inputs)

                        var $row      = $(row);

                        var order     = $row.find('.form-row-order').val();
                        var qty       = $row.find('.qty').val();
                        var type      = $row.find('.toggle-type').val();
                        var details   = $row.find('.details').val();
                        var unitPrice = $row.find('.unit_price').val();
                        var vat       = $row.find('.vat').val();

                        if((type != commentType && (qty == "" || type == "" || details == "" || unitPrice == "" || vat == "")) || (type == commentType && details == "")) {
                            sendFinalRequest = false;
                            alert('Please fill all fields ');
                            return;
                        }

                        if(i >= $lastSavedRow) {
                            // if were added few estimate items - their processing
                            sendFinalRequest = false;

                            $.ajaxSetup({async: false});
                            $.post("/finance/invoice-items/ajax/add-row", {
                                index: index, qty: qty, type: type, details: details, order: order,
                                unitPrice: unitPrice, vat: vat, _token: self.getInvoice().getToken()
                            },
                            function(data, status) {
                                if(status != 'success') {
                                    return;
                                }
                                index++;
                                $('.table-body').append(data);
                                $row.remove();
                                sendFinalRequest = true;
                                self.recalculatePrices();
                            });
                        }
                    }
                });

                if(sendFinalRequest) {
                    // final submit request => save all invoice items
                    $.ajax({
                        url: self.getInvoice().getSendFinalRequestUrl(),
                        headers: { 'X-CSRF-TOKEN': self.getInvoice().getToken() },
                        type: 'post',
                        data: $('.all-invoice-items').serialize(),
                        async : false,
                        success: function (result) { },
                        error: function() { }
                    });
                    window.location.reload();
                }
            });


            // rm button click
            $(document).on('click', '.btn-rm-row-form', function() {
                if(!confirm('Are you sure?')) {
                    return;
                }

                var $tdNode = $(this).closest('.table-row-form-added');

                if($tdNode.data('id') != "") {
                    $.post("/finance/invoice-items/delete", { id: $tdNode.data('id'), _token: self.getInvoice().getToken() },
                        function(data, status) {
                            if(status != 'success') {
                                console.log(status);
                                return;
                            }
                            $tdNode.remove();
                            self.recalculatePrices();
                        });
                }
                else $tdNode.remove();
            });


            // redraw form
            $(document).on('change', '.toggle-type', function() {
                self.checkFormType($(this));
            });


            // recalculate price
            self.recalculatePrices();

            $(document).on('change', '.vat', function() {
                self.recalculatePrices();
            });

            $(document).on('change paste keyup', '.unit_price', function(e) {
                e.preventDefault();
                if($(this).val() != '-' && !$.isNumeric($(this).val())) {
                    $(this).val($(this).val().slice(0, -1));
                    alert('Unit Price is not a valid!');
                    return false;
                }
                self.recalculatePrices();
            });

            $(document).on('change paste keyup', '.qty', function(e) {
                e.preventDefault();
                if(!$.isNumeric($(this).val())) {
                    $(this).val($(this).val().slice(0, -1));
                    alert('Quantity is not a valid!');
                    return false;
                }
                self.recalculatePrices();
            });

            $('.input-type-only-float-positive-digits').on('keydown', function(e) {
                utilClass.onlyFloatPositiveDigits(e);
            });

            $('.input-type-only-digits').on('keydown', function(e) {
                utilClass.onlyDigits(e);
            });

        });
    };

    this.checkFormType = function($this) {
        var $thisQty = $this.closest('.table-form-container-row').find('.qty'),
            $thisUnitPrice = $this.closest('.table-form-container-row').find('.unit_price'),
            $thisVat = $this.closest('.table-form-container-row').find('.vat'),
            $thisNetSubtotal = $this.closest('.table-form-container-row').find('.net');

        if($this.val() == commentType){
            self.toggleForm(true, $thisQty, $thisUnitPrice, $thisVat, $thisNetSubtotal);
        } else {
            self.toggleForm(false, $thisQty, $thisUnitPrice, $thisVat, $thisNetSubtotal);
        }
    };

    this.toggleForm = function(isCommentType, $qty, $unitPrice, $vat, $netSubtotal) {
        if(isCommentType) {
            $qty.css('visibility', 'hidden').prop('required',false);
            $unitPrice.css('visibility', 'hidden').prop('required',false);
            $vat.css('visibility', 'hidden').prop('required',false);
            $netSubtotal.html('');
        } else {
            $qty.css('visibility', 'visible');
            $unitPrice.css('visibility', 'visible');
            $vat.css('visibility', 'visible');

            if($qty.attr('id') != 'quantity') $qty.prop('required',true);
            if($unitPrice.attr('id') != 'unit_price') $unitPrice.prop('required',true);
            if($vat.attr('id') != 'vat') $vat.prop('required',true);
        }

        self.recalculatePrices();
    };

    this.recalculatePrices = function() {
        var discount = 0, netSubtotal = 0, vatSubtotal = 0;
        $('.table-body').find('tr').each(function (i, row) {
            var $row      = $(row);
            var $net      = $row.find('.net');

            var type      = $row.find('.toggle-type').val();
            var qty       = $row.find('.qty').val();
            var unitPrice = $row.find('.unit_price').val();
            var vat       = $row.find('.vat').val();

            if(type == commentType) return;

            var net = qty * unitPrice;
            netSubtotal += net;
            vatSubtotal += ((net * vat) / 100);

            $net.text(utilClass.formatPriceWithoutCurrency(net));
        });
        discount = $invoiceDiscount > 0 ? ((netSubtotal * $invoiceDiscount) / 100) : discount;
        $discount.html(utilClass.formatPriceWithoutCurrency(discount));
        netSubtotal = netSubtotal - discount;
        $netSubtotal.html(utilClass.formatPriceWithoutCurrency(netSubtotal));
        vatSubtotal = $invoiceDiscount > 0 ? (vatSubtotal - ((vatSubtotal * $invoiceDiscount)) / 100) : vatSubtotal;
        $vatSubtotal.html(utilClass.formatPriceWithoutCurrency(vatSubtotal));
        $total.html('<strong>' + self.getInvoice().getInvoiceCurrencySymbol() + utilClass.formatPriceWithoutCurrency(netSubtotal + vatSubtotal) + '</strong>');
    };

    this.deleteInvoiceFile = function(element) {
        FileUploadHandler.token = self.getInvoice().getToken();
        FileUploadHandler.delete(element);
        $(element).parent().remove();
    };

};
