function Utils() {

    var self = this;

    // linkify section
    this.linkifySection = function(identifier) {
        $(identifier).linkify({
            target: "_blank"
        });
    };

    this.hrefTargetBlankForSection = function(identifier) {
        $(identifier).find('a').each(function() {
            if( $(this).attr('target') != '_blank' ) {
                $(this).attr('target', '_blank');
            }
        });
    };

    this.formatPriceWithoutCurrency = function(string) {
        return accounting.formatMoney(string).substring(1);
    };

    this.onlyFloatPositiveDigits = function(e) {
        if (self.handleFloatPositiveDigits(e)) {
            // let it happen, don't do anything
            return true;
        }

        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    };

    this.positiveDigits = function(e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 ||
                // Allow: Ctrl+A, Command+A
            (e.keyCode == 65 && ( e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return true;
        }
    };

    this.handleFloatPositiveDigits = function(e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 ||
            self.positiveDigits(e) ||
                // Allow: point (.)
            (e.keyCode == 190)) {
            // let it happen, don't do anything
            return true;
        }
    };

    this.negativeDigits = function(e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 ||
            self.positiveDigits(e) ||
                // Allow: point (.)
            (e.keyCode == 189)) {
            // let it happen, don't do anything
            return true;
        }
    };

    this.onlyDigits = function(e) {
        if (self.handleFloatPositiveDigits(e) || self.negativeDigits(e)) {
            // let it happen, don't do anything
            return true;
        }

        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    };

    this.resetAllTypeInputs = function(parentElement) {
        parentElement.find('input:text, input:password, input:hidden, input:button, input:submit, image, textarea').val('');
        parentElement.find('input:radio, input:checkbox').prop('checked', false);
        parentElement.find('select').prop('selectedIndex', -1);
    };

};
