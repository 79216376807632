var DragAndDropFilesHandler = {
    content_tab : "",

    initialisation: function() {
        if(window.File && window.FileList && window.FileReader) {
            var filesDrag = document.getElementById("uploadFilesWithDropZone");
            filesDrag.addEventListener("dragover", DragAndDropFilesHandler.dragFileDragHover, false);
            filesDrag.addEventListener("dragleave", DragAndDropFilesHandler.dragFileDragHover, false);
        }
    },

    dragFileDragHover: function(e) {
        e.stopPropagation();
        e.preventDefault();
        $('.nav-tabs a[href="#' + DragAndDropFilesHandler.content_tab + '"]').tab('show');
        // Error alert! Remove all errors and hide it.
        $('div#drop-zone-upload-errors .alert.alert-danger ul').empty();
        $('div#drop-zone-upload-errors').addClass('hidden-by-default');
    }

};