function ImageCropper(beforeAjaxRequest, dataAjaxRequest, callBackFunction, completeAjaxRequest) {
    this.$beforeAjaxRequest = beforeAjaxRequest;
    this.$dataAjaxRequest = dataAjaxRequest;
    this.$request = new AjaxRequest(this.$dataAjaxRequest);
    this.$callBackFunction = callBackFunction;
    this.$completeAjaxRequest = completeAjaxRequest;

    var self = this,
        $cropperImage = $('#cropper-image'),
        $sectionImage = $('.img-container'),
        $loading = $('#loading');
    var options = {
        aspectRatio: 1 / 1,
        preview: '.img-preview'
    };

    // methods
    this.getDataAjaxRequest = function() {
        return this.$dataAjaxRequest;
    };
    this.setDataAjaxRequest = function(dataAjaxRequest) {
        this.$dataAjaxRequest = dataAjaxRequest;
    };

    // request results
    this.beforeAjaxRequest = function() {
        $sectionImage.addClass('hidden-by-default');
        $loading.removeClass('hidden-by-default');

        self.$beforeAjaxRequest();
    };
    this.doneAjaxRequest = function(result) {
        self.completeAjaxRequest();
        setTimeout(function() {
            if(self.$callBackFunction != null) {
                self.$callBackFunction.callBackFunction(result);
            }
            $.bootstrapGrowl(jQuery.parseJSON(result).message, { delay : 2000, type : "success" });
        }, 2000);
    };
    this.failAjaxRequest = function(request, error) {
        $.bootstrapGrowl("There has been an error", { delay : 2000, type : "danger" });
    };
    this.completeAjaxRequest = function(xhr, statusText) {
        setTimeout(function() {
            $loading.addClass('hidden-by-default');
            $sectionImage.removeClass('hidden-by-default');

            self.$completeAjaxRequest();
        }, 2000);
    };

    // tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // cropper
    $cropperImage.on({
        'build.cropper': function(e) { },
        'built.cropper': function(e) { },
        'cropstart.cropper': function(e) { },
        'cropmove.cropper': function(e) { },
        'cropend.cropper': function(e) { },
        'crop.cropper': function(e) { },
        'zoom.cropper': function(e) { }
    }).cropper(options);

    // buttons
    if(!$.isFunction(document.createElement('canvas').getContext)) {
        $('button[data-method="getCroppedCanvas"]').prop('disabled', true);
    }

    if(typeof document.createElement('cropper').style.transition === 'undefined') {
        $('button[data-method="rotate"]').prop('disabled', true);
        $('button[data-method="scale"]').prop('disabled', true);
    }

    // options
    $('.docs-toggles').on('change', 'input', function () {
        var $this = $(this);
        var name = $this.attr('name');
        var type = $this.prop('type');
        var cropBoxData;
        var canvasData;

        if(!$cropperImage.data('cropper')) {
            return;
        }

        if(type === 'checkbox') {
            options[name] = $this.prop('checked');
            cropBoxData = $cropperImage.cropper('getCropBoxData');
            canvasData = $cropperImage.cropper('getCanvasData');

            options.built = function () {
                $cropperImage.cropper('setCropBoxData', cropBoxData);
                $cropperImage.cropper('setCanvasData', canvasData);
            };
        } else if(type === 'radio') {
            options[name] = $this.val();
        }

        $cropperImage.cropper('destroy').cropper(options);
    });

    // methods
    $('.docs-buttons').on('click', '[data-method]', function() {
        var $this = $(this);
        var data = $this.data();
        var $target;
        var result;

        if($this.prop('disabled') || $this.hasClass('disabled')) {
            return;
        }

        if($cropperImage.data('cropper') && data.method) {
            data = $.extend({}, data); // Clone a new one

            if (typeof data.target !== 'undefined') {
                $target = $(data.target);

                if (typeof data.option === 'undefined') {
                    try {
                        data.option = JSON.parse($target.val());
                    } catch (e) {
                        $.bootstrapGrowl(e.message, {delay: 2000, type: "danger"});
                    }
                }
            }

            if(data.method === 'rotate') {
                $cropperImage.cropper('clear');
            }

            result = $cropperImage.cropper(data.method, data.option, data.secondOption);

            if(data.method === 'rotate') {
                $cropperImage.cropper('crop');
            }

            switch(data.method) {
                case 'scaleX':
                case 'scaleY':
                    $(this).data('option', -data.option);
                    break;

                // crop event
                case 'getCroppedCanvas':
                    if (result) {
                        self.$request.setBeforeAjaxRequest(self.beforeAjaxRequest);
                        self.$request.setDoneAjaxRequest(self.doneAjaxRequest);
                        self.$request.setFailAjaxRequest(self.failAjaxRequest);
                        self.$request.setCompleteAjaxRequest(self.completeAjaxRequest);

                        var $currentDataAjaxRequest = { };
                        self.$dataAjaxRequest.getDataAjaxRequest().forEach(function(value, key) {
                            $currentDataAjaxRequest[key] = value;
                        });
                        $currentDataAjaxRequest['avatar'] = result.toDataURL('image/jpeg');
                        self.$request.execute($currentDataAjaxRequest);
                    }
                    break;
            }

            if ($.isPlainObject(result) && $target) {
                try {
                    $target.val(JSON.stringify(result));
                } catch (e) {
                    // console.log(e.message);
                    $.bootstrapGrowl(e.message, {delay: 2000, type: "danger"});
                }
            }
        }
    });

    // keyboard events
    $(document.body).on('keydown', function(e) {
        if (!$cropperImage.data('cropper') || this.scrollTop > 300) {
            return;
        }

        switch(e.which) {
            case 37:
                e.preventDefault();
                $cropperImage.cropper('move', -1, 0);
                break;

            case 38:
                e.preventDefault();
                $cropperImage.cropper('move', 0, -1);
                break;

            case 39:
                e.preventDefault();
                $cropperImage.cropper('move', 1, 0);
                break;

            case 40:
                e.preventDefault();
                $cropperImage.cropper('move', 0, 1);
                break;
        }
    });

    // import/load image
    var $inputImage = $('#inputImage');
    var URL = window.URL || window.webkitURL;
    var blobURL;

    if(URL) {
        $inputImage.change(function () {
            var files = this.files;
            var file;

            if(!$cropperImage.data('cropper')) {
                return;
            }

            if(files && files.length) {
                file = files[0];

                if(/^image\/\w+$/.test(file.type)) {
                    blobURL = URL.createObjectURL(file);
                    $cropperImage.one('built.cropper', function () {
                        // revoke when load complete
                        URL.revokeObjectURL(blobURL);
                    }).cropper('reset').cropper('replace', blobURL);

                    $inputImage.val('');
                } else {
                    //window.alert('Please choose an image file.');
                    self.$messageAlert.setTitle();
                }
            }
        });
    } else {
        $inputImage.prop('disabled', true).parent().addClass('disabled');
    }
};