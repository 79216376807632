function NoteManager(listNotesIdentifier, readonlyListNotes, note) {
    this.$listNotesIdentifier = listNotesIdentifier;
    this.$readonlyListNotes = readonlyListNotes;
    this.$note = note;

    // methods
    this.getListNotesIdentifier = function() {
        return this.$listNotesIdentifier;
    };
    this.setListNotesIdentifier = function(listNotesIdentifier) {
        this.$listNotesIdentifier = listNotesIdentifier;
    };
    this.isReadonlyListNotes = function() {
        return this.$readonlyListNotes;
    };
    this.setReadonlyListNotes = function(readonlyListNotes) {
        this.$readonlyListNotes = readonlyListNotes;
    };

    this.getNote = function() {
        return this.$note;
    };

    this.toggleDim = function() {
        if($(this.$listNotesIdentifier).css('opacity') < 1) {
            $(this.$listNotesIdentifier).css('opacity', 1);
            $(this.$note.getCreateNoteFormIdentifier()).css('opacity', 1);
        } else {
            $(this.$listNotesIdentifier).css('opacity', 0.5);
            $(this.$note.getCreateNoteFormIdentifier()).css('opacity', 0.5);
        }
    };

    this.getNotes = function() {
        var self = this, note = this.$note;
        self.toggleDim();

        $.ajax({
            url: '/notes/index',
            headers: {
                'X-CSRF-TOKEN': note.getToken()
            },
            type: 'POST',
            async: true,
            data: { readonly : self.isReadonlyListNotes(), content_id : note.getContentId(), content_type : note.getContentType() },
            success: function (result) {
                $(note.getNoteIdentifier()).html(result);
                $(note.getNoteIdentifier() + ' .contents').linkify({
                    target: "_blank"
                });
                self.toggleDim();
            }
        });
    };

    this.createNote = function() {
        var self = this, note = this.$note;
        this.toggleDim();

        $.ajax({
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': note.getToken()
            },
            url: $(note.getCreateNoteFormIdentifier()).attr('action'),
            data: $(note.getCreateNoteFormIdentifier()).serialize(),
            success: function (result) {
                $(note.getCreateNoteFormIdentifier() + ' textarea').val('');
                $(self.$listNotesIdentifier).html(result);
                $(self.$listNotesIdentifier + ' .contents').linkify({
                    target: "_blank"
                });
                self.toggleDim();
            }
        });
    };

    this.noteEdit = function(id) {
        var $contents = $("div#note-" + id + " .contents");
        if($contents.hasClass('editing')) return;
        $contents.addClass('editing');
        $contents.html("<textarea onblur='noteManager.noteEditSubmit("+id+",this)' class='form-control'>"+$contents.html()+"</textarea>");
        $contents.find('textarea').focus();
    };

    this.noteDelete = function(id, obj) {
        if(!confirm('Do you really want to delete this note?')) return false;

        var self = this;
        this.toggleDim();

        var $container = $("div#note-container-"+id);
        $.ajax({
            url: '/notes/delete',
            type: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': this.$note.getToken()
            },
            data: "id="+id,
            success: function (result) {
                $("div#note-"+id).effect("highlight", {}, 100, function(){$container.slideUp('fast', function(){$container.remove()})});
                self.toggleDim();
            }
        });
    };

    this.noteEditSubmit = function(id, obj) {
        var self = this;
        this.toggleDim();

        var $contents = $("div#note-" + id + " .contents");
        $contents.removeClass('editing');

        $.ajax({
            url: '/notes/patch',
            type: 'PATCH',
            headers: {
                'X-CSRF-TOKEN': this.$note.getToken()
            },
            data: "id="+id+"&text="+encodeURIComponent($(obj).val()),
            success: function (result) {
                $contents.html(result);
                $contents.linkify({
                    target: "_blank"
                });
                $("div#note-"+id).effect("highlight", {}, 1000);
                self.toggleDim();
            }
        });
    };

};
