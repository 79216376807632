function UserUIChangeAvatar(avatarIdentifiers) {
    this.$avatarIdentifiers = avatarIdentifiers;

    // gets and sets methods
    this.getAvatarIdentifiers = function() {
        return this.$avatarIdentifiers;
    };
    this.setAvatarIdentifiers = function(avatarIdentifiers) {
        this.$avatarIdentifiers = avatarIdentifiers;
    };

    this.callBackFunction = function(data) {
        $.map(this.$avatarIdentifiers, function(uiAvatar) { $(uiAvatar).prop('src', jQuery.parseJSON(data).data); });
    };

};
